<script setup>
import { getTimesBetween } from '@/utilities';
import { zipObject } from 'lodash';
import { computed, inject } from 'vue';

const dayjs = inject('dayjs');

const props = defineProps({
    shift: Object,
    form: Object,
});

const startTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.start).subtract(12, 'hour'),
        dayjs(props.shift.start).add(12, 'hour'),
        'minute',
        1
    );

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});

const endTimeOptions = computed(() => {
    const times = getTimesBetween(
        dayjs(props.shift.end).subtract(12, 'hour'),
        dayjs(props.shift.end).add(12, 'hour'),
        'minute',
        1
    );

    times.push(dayjs(props.shift.start));

    times.sort((a, b) => (a.isBefore(b) ? -1 : 1));

    return zipObject(
        times.map((e) => e.format('YYYY-MM-DD HH:mm')),
        times.map((e) => e.format('dd D MMM, HH:mm'))
    );
});

const breakOptions = computed(() => {
    var times = {};

    for (var i = 0; i < 9; i++) {
        times[15 * i * 60] = 15 * i + ' min';
    }
    return times;
});
</script>

<template>
    <div>
        <FormKit
            :label="$t('Start time')"
            type="select"
            name="start"
            :options="startTimeOptions"
            select-icon="down"
            v-model="form.start"
            :value="form.start"
            :errors="form.errors.start"
        />
        <FormKit
            :label="$t('End time')"
            type="select"
            name="end"
            :options="endTimeOptions"
            select-icon="down"
            v-model="form.end"
            :value="form.end"
            :errors="form.errors.end"
        />
        <FormKit
            :label="$t('Break')"
            type="select"
            name="break"
            :options="breakOptions"
            select-icon="down"
            v-model="form.break"
            :value="form.break"
            :errors="form.errors.break"
        />
    </div>
</template>
